
import {Component, Mixins, Prop} from 'vue-property-decorator';
import StateHelper from "../mixins/state-helper";
import SvgConstants from "../mixins/svg-constants";
import PreflightLink from "@/components/PreflightLink.vue";
import {
    FrontendLegalDocument,
    FrontendLegalDocumentType
} from "@/models/frontend-legal-document";
import {Global} from "@/global";


/**
 * This component represents a legal document. There are two cases: either we
 * have a PDF document with a link, or we have a "telegram" that is displayed
 * inline in the component, if the title is clicked.
 */
@Component({
    computed: {
        FrontendLegalDocumentType() {
            return FrontendLegalDocumentType
        }
    },
    components: {
        PreflightLink
    }
})
export default class SearchPageEntityLegalDocumentListingDocument extends Mixins(StateHelper, SvgConstants) {
    @Prop()
    document: FrontendLegalDocument;

    @Prop()
    legalDocumentListingId: number;

    expanded: boolean = false;

    /**
     * Gets the url where a given document can be downloaded.
     *
     * @param documentId The id of the document.
     */
    documentUrl(documentId: string): string {
        return Global.url("/sapi/legal-document/open/" + this.legalDocumentListingId + "/" + documentId + this.subRefNoParam);
    }

    /**
     * Render the display text for a document in the list.
     *
     * @param document The document.
     */
    renderTitle(document: FrontendLegalDocument) {
        return document.title;
    }

    /**
     * Render the body text for a document. Only valid for "telegram" type documents.
     */
    renderBody(document: FrontendLegalDocument) {
        return document.body;
    }

    /**
     * Render the info text for a document. Only valid for "telegram" type documents.
     */
    renderInfo(document: FrontendLegalDocument) {
        return document.info;
    }

    /**
     * Convenience method for creating a parameter "subscription" with the refNo
     * of the active subscription to be added to an url.
     */
    get subRefNoParam(): string {
        return "?subscription=" + this.activeSubscriptionRefNo;
    }
}
