/**
 * This class corresponds to the java class with the same name. See that class
 * for more documentation.
 */
export class FrontendLegalDocument {
    documentId: string = "";

    type: FrontendLegalDocumentType;

    title: string;

    body: string | null = null;

    info: string | null = null;

    timestamp: string | null = null;
}

export enum FrontendLegalDocumentType {
    DOCUMENT = 'DOCUMENT',
    TELEGRAM = 'TELEGRAM',
}
